import React, { useState, useEffect } from "react";
import axios from "axios";
import "./DailyForecastStyles.css";
import { ForecastCoords } from "../WeatherMap";

// Condition -> Icon mapping
const conditionIcons = {
  Sunny: "./assets/forecast/Sunny.svg",
  SunnyHot: "./assets/forecast/SunnyHot.svg",
  SunnyCold: "./assets/forecast/SunnyCold.svg",
  Cloudy: "./assets/forecast/Cloudy.svg",
  PartlyCloudy: "./assets/forecast/PartlyCloudyBlush.svg",
  PartlyCloudyCold: "./assets/forecast/PartlyCloudyCold.svg",
  Snowy: "./assets/forecast/Snowy.svg",
  Stormy: "./assets/forecast/Stormy.svg",
  Sleety: "./assets/forecast/Sleety.svg",
  Mix: "./assets/forecast/Mix.svg",
  MoonCloudy: "./assets/forecast/MoonCloudy.svg",
  Moony: "./assets/forecast/Moony.svg",
  RainHeavy: "./assets/forecast/RainHeavy.svg",
  RainLight: "./assets/forecast/RainLight.svg",
  Rain: "./assets/forecast/Rain.svg",
  Snow: "./assets/forecast/Snow.svg",
  Sleet: "./assets/forecast/Sleet.svg",
  FrzRain: "./assets/forecast/FrzRain.svg",
  FrzRainLight: "./assets/forecast/FrzRainLight.svg",
  // fallback
  default: "./assets/forecast/Cloudy.svg",
};

function getConditionIcon(condition) {
  return conditionIcons[condition] || conditionIcons.default;
}

export default function DailyForecastDisplay({
  onShowHourlyForecast,
  onClose

}) {
  const [dailyForecasts, setDailyForecasts] = useState([]);
  const [startDate, setStartDate] = useState(new Date());

  // Function to format date in YYYY-MM-DD format
  const formatDate = (date) => {
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
      2,
      "0"
    )}-${String(date.getDate()).padStart(2, "0")}`;
  };

  useEffect(() => {
    const fetchDailyForecasts = async () => {
      const API_KEY = process.env.REACT_APP_API_KEY;
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const lon = Number(ForecastCoords.Provider[0]);
      const lat = Number(ForecastCoords.Provider[1]);
      const formattedDateDay = formatDate(startDate);

      try {
        const response = await axios.post(`${backendUrl}/api/forecast`, {
          location: [lon, lat],
          day: formattedDateDay,
          model_res: API_KEY,
          forecast_type: "daily",
        });

        if (response.data && response.data.weather_data) {
          setDailyForecasts(response.data.weather_data);
        } else {
          setDailyForecasts([]);
        }
      } catch (error) {
        console.error("Error fetching daily forecast:", error);
        setDailyForecasts([]);
      }
    };

    fetchDailyForecasts();
  }, [startDate]);

  return (
    <div className="forecastWindow">
      <div className="dailyforecastpopup">
        <button className="close-button-daily" onClick={onClose}>
          X
        </button>
        <div className="daily-forecast-container">
          {dailyForecasts.length > 0 &&
            dailyForecasts.map((day, index) => {
              const [year, month, dayNum] = day.date.split("-");
              const friendlyDate = new Date(
                year,
                month - 1,
                dayNum
              ).toLocaleDateString("en-US", {
                weekday: "short",
                month: "short",
                day: "2-digit",
              });

              return (
                <div className="daily-forecast-card" key={index}>
                  <div>
                    <div className="ddate">{friendlyDate}</div>
                    <div className="modelrun">{day.model_run}</div>
                  </div>

                  <div className="iconContainer">
                    <img
                      className="condition-icon"
                      src={getConditionIcon(day.condition)}
                      alt={day.condition}
                    />
                  </div>

                  <div className="temps-row">
                    <span className="temp-max">{day.max_temperature}°</span>
                    <span className="temp-separator"> / </span>
                    <span className="temp-min">{day.min_temperature}°</span>
                  </div>

                  <div className="card-content">
                    {day.snow !== 0 && (
                      <div className="parameter-row precip-parameter">
                        <span className="parameter-label">Snow (total):</span>
                        <span className="parameter-value">{day.snow}″</span>
                      </div>
                    )}
                    {day.sleet !== 0 && (
                      <div className="parameter-row precip-parameter">
                        <span className="parameter-label">Sleet (total):</span>
                        <span className="parameter-value">{day.sleet}″</span>
                      </div>
                    )}
                    {day.frzrain !== 0 && (
                      <div className="parameter-row precip-parameter">
                        <span className="parameter-label">
                          Frz Rain (total):
                        </span>
                        <span className="parameter-value">{day.frzrain}″</span>
                      </div>
                    )}
                    {day.rain !== 0 && (
                      <div className="parameter-row precip-parameter">
                        <span className="parameter-label">Rain (total):</span>
                        <span className="parameter-value">{day.rain}″</span>
                      </div>
                    )}

                    <div className="parameter-row">
                      <span className="parameter-label">Wind (max):</span>
                      <span className="parameter-value">
                        {day.max_wind} mph
                      </span>
                    </div>
                    <div className="parameter-row">
                      <span className="parameter-label">Gusts (max):</span>
                      <span className="parameter-value">
                        {day.max_wind_gusts} mph
                      </span>
                    </div>
                    <div className="parameter-row">
                      <span className="parameter-label">Clouds (avg):</span>
                      <span className="parameter-value">{day.clouds}%</span>
                    </div>
                  </div>
                  <button
  className="hourly-link"
  onClick={() => {
    const dateObj = new Date(year, month - 1, dayNum);

    let modelType = "gfs"; // Default model
    if (day.model_run?.includes("NAM")) {
      modelType = "nam";
    } else if (day.model_run?.includes("HRRR")) {
      modelType = "hrrr";
    }

    onShowHourlyForecast(dateObj, modelType);
  }}
>
  View Hourly
</button>

                  {/* <button
                    className="hourly-link"
                    onClick={() => {
                      const dateObj = new Date(year, month - 1, dayNum);
                      const isNam =
                        day.model_run && day.model_run.includes("NAM");

                      onShowHourlyForecast(dateObj, isNam ? "nam" : "gfs");
                    }}
                  >
                    View Hourly
                  </button> */}
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
