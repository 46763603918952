// CoordinatesContextLoc.js
import { createContext, useContext, useState } from 'react';

const CoordinatesContextLoc = createContext();

export const useCoordinatesLoc = () => {
  const context = useContext(CoordinatesContextLoc);
  if (!context) {
    throw new Error('useCoordinatesLoc must be used within a CoordinatesProviderLoc');
  }
  return context;
};

export const CoordinatesProviderLoc = ({ children }) => {
  const [coordinatesLoc, setCoordinatesLoc] = useState(null);

  return (
    <CoordinatesContextLoc.Provider value={[coordinatesLoc, setCoordinatesLoc]}>
      {children}
    </CoordinatesContextLoc.Provider>
  );
};
