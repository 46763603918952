import React, { useState, useEffect, useRef } from "react";
import { createRoot } from "react-dom/client";
import "./ForecastStyles.css";
import HourlyForecastDisplay from "./HourlyForecastDisplay";
import DailyForecastDisplay from "./DailyForecastDisplay";


//This is the parent for all forecast components
export default function ShowForecast({ bypassPopup = false }) {
  const forecastRef = useRef(null);
  const rootRef = useRef(null);

  const [showDailyForecast, setShowDailyForecast] = useState(bypassPopup);
  const [showHourlyForecast, setShowHourlyForecast] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedModel, setSelectedModel] = useState("gfs"); // Controls the model selection
  const [forecastKey, setForecastKey] = useState(0); // Forces re-render only when necessary

  // Handler to show the daily forecast window
  function showDailyForecastHandler() {
    setShowDailyForecast(true);
  }

  // Handler to show the hourly forecast window
  function showHourlyForecastHandler(date, model) {
    setSelectedDate(date);
    if (model !== selectedModel) {
      setSelectedModel(model);
    }
    setShowHourlyForecast(true);
  }

  // Handler for updating the model from the dropdown
  function handleModelChange(newModel) {
    if (newModel !== selectedModel) {
      setSelectedModel(newModel);
    }
  }

  // Only update forecastKey when selectedModel actually changes
  useEffect(() => {
    setForecastKey((prevKey) => prevKey + 1);
  }, [selectedModel]); // This effect only triggers when selectedModel changes

  useEffect(() => {
    if (showDailyForecast || showHourlyForecast) {
      if (!forecastRef.current) {
        forecastRef.current = document.createElement("div");
        forecastRef.current.className = "forecast-container";
        document.body.appendChild(forecastRef.current);
        rootRef.current = createRoot(forecastRef.current);
      }

      rootRef.current.render(
        <>
          {showDailyForecast && (
            <DailyForecastDisplay
              onShowHourlyForecast={showHourlyForecastHandler}
              onClose={() => setShowDailyForecast(false)}
            />
          )}
          {showHourlyForecast && (
            <HourlyForecastDisplay
              // key={forecastKey}
              onClose={() => setShowHourlyForecast(false)}
              selectedDate={selectedDate}
              selectedModel={selectedModel}
              setSelectedModel={handleModelChange}
            />
          )}
        </>
      );
    } else {
      if (forecastRef.current) {
        forecastRef.current.remove();
        forecastRef.current = null;
        rootRef.current = null;
      }
    }
  }, [showDailyForecast, showHourlyForecast, selectedDate, forecastKey]);

  return bypassPopup ? null : (
    <div>
      <button className="forecastbutton" onClick={showDailyForecastHandler}>
        Show Forecast
      </button>
    </div>
  );
}
