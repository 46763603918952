// LocationButton.js
import L from 'leaflet';
import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';
import styles from './Map.module.css';
import { useCoordinatesLoc } from "./CoordinatesContextLoc";
const LocationButton = ({ onLocationFound }) => {
  const map = useMap();
  const [userLocation, setUserLocation] = useState(null);
  const [coordinatesLoc, setCoordinatesLoc] = useCoordinatesLoc();

  useEffect(() => {
    map.locate();
    const handleLocationFound = (e) => {
      setUserLocation(e.latlng);
      // Immediately pass the detected location to the parent
      // onLocationFound(e.latlng);
      setCoordinatesLoc(e.latlng)
    };
    map.on('locationfound', handleLocationFound);

    return () => {
      map.off('locationfound', handleLocationFound);
    };
  }, [map]);

  useEffect(() => {
    if (userLocation && !map.zoomToLocationControl) {
      L.Control.Watermark = L.Control.extend({
        onAdd: function () {
          var img = L.DomUtil.create('img');
          img.src = './assets/zoom-to-location.svg';
          img.style.width = '33px';
          img.style.border = '1px solid grey';
          img.title = 'zoom to location';
          img.className = styles.zoom_to_location_button; // Apply the CSS module class
          
          img.onclick = function () {
            if (userLocation) {
              const blueDotIcon = L.divIcon({
                className: 'blue-dot-icon',
                html: '<div style="background-color: rgba(0, 0, 255, 0.65); width: 12px; height: 12px; border-radius: 50%;"></div>',
                iconSize: [12, 12],
                iconAnchor: [6, 6],
              });

              L.marker(userLocation, { icon: blueDotIcon }).addTo(map);
              map.flyTo(userLocation, 12);
              // This click now isn't the only way to pass the location.
              // onLocationFound(userLocation); // Optionally, you can leave this commented.
            }
          };
          return img;
        },
        onRemove: function () {
          // Nothing to do here
        },
      });

      L.control.watermark = function (opts) {
        return new L.Control.Watermark(opts);
      };

      map.zoomToLocationControl = L.control.watermark({ position: 'topleft' }).addTo(map);
    }
  }, [map, userLocation, onLocationFound]);

  return null;
};

export default LocationButton;
