import React from 'react';

export default function ForecastModelDropDown({
  selectedModel,
  setSelectedForecastModel
}) {
  const handleChange = (e) => {
    const newModel = e.target.value;
    setSelectedForecastModel(newModel);
  };

  return (
    <select value={selectedModel} onChange={handleChange}>
      <option value="gfs">GFS</option>
      <option value="nam">NAM</option>
      <option value="hrrr">HRRR</option>
    </select>
  );
}
