import axios from "axios";
import { useState, useEffect, useCallback } from "react";
import { ForecastCoords } from "../WeatherMap";

function GenerateForecastData(selectedDate, setSelectedDate, selectedForecastModel) {
  const [isLastDay, setIsLastDay] = useState(false); // Track if it's the last day
  const [alldata, setAlldata] = useState([]); // Store forecast data for the current day
  const minDate = new Date(); // Today
  minDate.setHours(0, 0, 0, 0);

  const maxDate = new Date(new Date().setHours(new Date().getHours() + 384)); // Max forecast range
  maxDate.setHours(0, 0, 0, 0);

  // Function to request forecast for a specific day
  const getForecastForDate = useCallback((date) => {
    const lon = Number(ForecastCoords.Provider[0]);
    const lat = Number(ForecastCoords.Provider[1]);
  
    const API_KEY = process.env.REACT_APP_API_KEY;
    const formattedDateDay = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(
      date.getDate()
    ).padStart(2, "0")}`;
    const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
    // console.log("Sending forecast request with payload:", {
    //   location: [lon, lat],
    //   model: selectedForecastModel,
    //   day: formattedDateDay,
    //   model_res: API_KEY,
    //   forecast_type: 'hourly'
    // });

    return axios
      .post(`${backendUrl}/api/forecast`, {
        location: [lon, lat],
        model: selectedForecastModel,
        day: formattedDateDay,
        model_res: API_KEY,
        forecast_type: 'hourly'
      })
      .then((response) => {
        if (response.data.weather_data && response.data.weather_data.length > 0) {
          setAlldata(response.data.weather_data);
          setIsLastDay(false);
        } else {
          setAlldata([]);
          setIsLastDay(true); // Mark as last day if no data is returned
        }
      })
      .catch((error) => {
        console.error(error);
        setAlldata([]);
        setIsLastDay(true);
      });
  }, [selectedForecastModel]);

  // Function to handle the "Next Day" button click.
  // Instead of pre-checking for data here, we simply update the date
  // and let the effect fetch the forecast.
  const handleNextDay = () => {
    const nextDay = new Date(selectedDate);
    nextDay.setDate(nextDay.getDate() + 1);
    nextDay.setHours(0, 0, 0, 0);
  
    if (nextDay <= maxDate) {
      setSelectedDate(nextDay);
    }
  };

  // Function to handle the "Previous Day" button click
  const handlePreviousDay = () => {
    const previousDay = new Date(selectedDate.getTime() - 24 * 60 * 60 * 1000); // Subtract one day
    previousDay.setHours(0, 0, 0, 0);
  
    if (previousDay >= minDate) {

      setSelectedDate(previousDay);
      setIsLastDay(false); // Reset last day status when going back
    }
  };

  // Prepare the weather data for the UI
  const weatherdata = alldata ? alldata.map((data) => {
    const displayTime = new Date(data.forecast_time).toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const formattedTime = displayTime.replace(/:00 /, "").toLowerCase();
    const simplifiedTime = formattedTime.replace(/^0/, "");
    
    return {
      time: simplifiedTime,
      condition: data.condition,
      temp: data.temperature,
      precip: data.precipitation,
      wind: data.wind,
      gusts: data.windgusts,
      clouds: data.cloudcover,
      model_run: data.model_run_date,
    };
  }) : [];

  // Fetch forecast for the current date when it changes (or on mount)
  useEffect(() => {
    getForecastForDate(selectedDate);
  }, [selectedDate, getForecastForDate]);

  return { weatherdata, handleNextDay, handlePreviousDay, isLastDay };
}

export default GenerateForecastData;
