import React from "react";
import { Link } from "react-router-dom";
import "./About.css";
// import Title from "../Title";

function About() {
  return (
    <div id="about" className="about">
      {/* <Link to="/">
        <Title />
      </Link> */}

      <div className="about-content" style={{ marginTop: "20px" }}></div>
      <Link to="/">
        <img
          className="aboutimage1"
          src="./assets/forecast/Cloudy.svg"
          alt="Weather Icon"
        />
      </Link>
      <h1>About</h1>
      <p>
        Welcome to PTWeather! This patent-pending application is designed to
        provide comprehensive weather and climate data visualizations. Users can
        interact with various layers of weather/climate data, measure
        areas/distances, and analyze geospatial weather data for the past,
        present, and future. You can even get a forecast from multiple models.
      </p>

      <p>
        PTWeather is currently in its beta release and is constantly being added
        to and tested. The number of models, weather parameters, and overlays
        will grow. Below is an explanation of the current models and data
        available to you. There are also tutorials and videos showing how to use
        PTWeather here:
        <Link
          className="ptweather-youtube"
          to="https://www.youtube.com/@PTWeather"
        >
          (PTWeather YouTube)
        </Link>
      </p>

      <div>
        <h2>Medium Range Forecast Models:</h2>
        <ul>
          <li>
            <strong>GFS (Global Forecast System):</strong>
            <ul>
              <li>
                GFS Hourly: Provides global weather forecasts at an hourly
                resolution out 384 hours.
              </li>
              <li>
                GFS Daily: Aggregates GFS hourly data into daily summaries,
                showcasing daily averages, maximums, minimums, and anomalies.
              </li>
              <li>
                Graphcast: NWS trained Graphcast model. Trained using ERA5 data,
                and run using GDAS data as its first inputs to create a forecast
                out to 240 hours
              </li>
            </ul>
          </li>
        </ul>

        <h2>Short Range Forecast Models:</h2>
        <ul>
          <li>
            <strong>
              NAM (3k) (North American Mesoscale Forecast System):
            </strong>
            <ul>
              <li>
                A high-resolution model focusing on North America, delivering
                forecasts up to 60 hours ahead, with a 3-kilometer resolution.
              </li>
            </ul>
          </li>
          <li>
            <strong>HRRR (3k) (High-Resolution Rapid Refresh):</strong>
            <ul>
              <li>
                Offers highly detailed, hourly weather forecasts for the United
                States up to 48 hours out every 6 hours, and 18 hours out every
                other hour. Has a 3-kilometer resolution.
              </li>
            </ul>
          </li>
        </ul>

        <h2>Historical Models:</h2>
        <ul>
          <li>
            <strong>ERA5:</strong>
            <ul>
              <li>
                Provides hourly data on atmospheric, land, and oceanic climates.
              </li>
              <li>
                ERA5 Daily: Aggregates hourly data into daily summaries,
                available from January 1, 1996.
              </li>
              <li>
                ERA5 Monthly: Offers insights into longer-term climate trends,
                extending back to January 1941.
              </li>
            </ul>
          </li>
          <li>
            <strong>
              Reanalysis 24h and Season (Precipitation and Snow Reanalysis):
            </strong>
            <ul>
              <li>
                Precipitation data are multi-sensor estimates from radar and
                rain gauges, controlled for quality by the NWS.
              </li>
              <li>
                Snow reanalysis combines data from multiple observation networks
                to estimate past snowfall amounts. Created by the NWS National
                Gridded Snowfall Analysis.
              </li>
            </ul>
          </li>
        </ul>
        <h2>Menu Tabs:</h2>
        <ul>
          <p>
            There are three tabs on the PTWeather menu to choose from. These are
            "Instant", "Range", and "Graph". They all open powerful analysis
            possibilities for you. Below are a description of them.
          </p>
          <li>
            <strong>Instant:</strong>
            <ul>
              <li>
                A query on the "Instant" tab will return a weather map for an
                instant in time from any of the avaialble models and parameters.
                For example if you are interested in seeing what the weather
                will be like tomorrow at noon then you would use this tab.
                Location drawing options are rectangle and polygon.
              </li>
            </ul>
          </li>
          <li>
            <strong>Range:</strong>
            <ul>
              <li>
                A query on the "Range" tab will return a weather map for a range
                in time from any of the available models and parameters. For
                example if you wanted to see how much total rain will fall over
                the next week then you would use this tab. Location drawing
                options are rectangle and polygon.
              </li>
            </ul>
          </li>
          <li>
            <strong>Graph:</strong>
            <ul>
              <li>
                A query on the "Graph" tab will return a line graph of a weather
                parmater over time for a model. For example if you wanted to see
                how much the sea ice has declined over an area for the past 80
                years you would use this tab. Location drawing options are
                rectangle and point.
              </li>
            </ul>
          </li>
          <li>
            <strong>Correlation:</strong>
            <ul>
              <li>
                A query on the "Correlation" tab returns an analysis of how two
                weather parameters are related over time for a selected area.
                This feature calculates and visualizes the relationship between
                the parameters using statistical measures like Spearman and
                Pearson correlations. Additionally, a scatterplot with a LOWESS
                (Locally Weighted Scatterplot Smoothing) curve is included to
                capture and visualize any nonlinear trends in the data. For
                example, you can explore how sea surface temperatures and
                precipitation are linked in a specific region over a given time
                period. The output includes plots showing each parameter over
                time, a rolling correlation graph, and the LOWESS scatterplot,
                providing a comprehensive view of how the parameters interact
                and whether their relationship strengthens, weakens, or behaves
                nonlinearly across the dataset. Location selection options
                include drawing a rectangle or choosing a point on the map.
                <strong>
                  Two geometries must be drawn using the draw tool!
                </strong>
              </li>
            </ul>
          </li>
        </ul>
        <h2>Forecasts:</h2>
        <ul>
          <p>
            You can retrieve an hourly forecast from anywhere in the world by
            right-clicking on the map and choosing "Show Forecast". By default
            the forecast wil be set to retreive from the GFS model, but over the
            United States you will also be able to select the NAM and HRRR
            forecasts as well. The option to choose between the models appears
            once the forecast window appears. Each forecast window shows a
            single day at a time and gives the option to go to the next day,
            previous day, or selecting a specific date in the date picker.
          </p>
        </ul>
      </div>

      <h1>Contact</h1>
      <p>
        If you wish to contact PTWeather, you may at{" "}
        <a href="mailto:phil@ptweather.com" className="contact-email">
          phil@ptweather.com
        </a>
        . Comments, compliments, and feedback are very welcome.
      </p>
      <Link className="terms-link" to="/terms-of-use">
        <h1>Terms of Use</h1>
      </Link>
    </div>
  );
}

export default About;
