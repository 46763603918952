import proj4 from "proj4";
import { useMap } from "react-leaflet";
import parseGeoraster from "georaster";
import GeoRasterLayer from "georaster-layer-for-leaflet";
import geoblaze from "geoblaze";
import {
  React,
  useRef,
  useState,
  useEffect,
  useCallback,
} from "react";
import L from "leaflet";
import { MyTiff } from "./SideMenu/SideMenu";
import { createRoot } from "react-dom/client";
import { getStaticWeatherRange } from "./SetColorScale";

import {
  comRainScale,
  comFreezingRainScale,
  comSleetScale,
  comSnowScale,
  comMixScale,
} from "./SetColorScale";
import LegendContinuous from "./Legend/LegendContinuous";
import LegendDiscrete from "./Legend/LegendDiscrete";
import Measuring from "./Measure/MeasuringContext";
import ShowForecast from "./Forecast/ShowForecast";
import SetColorScale from "./SetColorScale";
import { ForecastCoords } from "./WeatherMap";

import styles from "./Map.module.css";
import chroma from "chroma-js";
window.proj4 = proj4;

export default function GeoTiffLayer({
  weather_file,
  rampType,
  rampScale,
  colorBlind,
  activeTabKey,
  parameter,
  opacity,
  selectedModel,
}) {
  const [minval, setminval] = useState(10);
  const [maxval, setmaxval] = useState(20);
  const [lockedMinMax, setLockedMinMax] = useState({});
  const [isLocked, setIsLocked] = useState(false);
  const [manualEdit, setManualEdit] = useState(false);
  const [latlngstate, setLatLngState] = useState(null);
  const [isPopup, setIsPopup] = useState(false);
  const [georasterState, setGeorasterState] = useState(null);
  const geoTiffLayerRef = useRef(null);
  const url = weather_file;
  const map = useMap();
  const prevMinValRef = useRef(minval);
  const prevMaxValRef = useRef(maxval);
  const colorScaleRef = useRef(null);
  const ignoreNextCloseRef = useRef(false);
  const isGFSOrERA5Model =
    selectedModel.startsWith("gfs") ||
    selectedModel.startsWith("era5") ||
    selectedModel.startsWith("graphcastgfs");
  const isTemperatureParameter = parameter.includes("temperature");
  const popupRef = useRef(null);

  // Function to create or update popup content
  //   const updatePopupContent = (latlng, georaster) => {
  //     const identifyValue = parseFloat(
  //         geoblaze.identify(georaster, [latlng.lng, latlng.lat])
  //     ).toFixed(2);

  //     const content = `<div>Value: ${identifyValue}</div>`;

  //     if (!popupRef.current) {
  //         popupRef.current = L.popup()
  //             .setLatLng([latlng.lat, latlng.lng])
  //             .setContent(content)
  //             .openOn(map);
  //     } else {
  //         popupRef.current
  //             .setLatLng([latlng.lat, latlng.lng])
  //             .setContent(content)
  //             .openOn(map);
  //     }
  // };

  const updatePopupContent = useCallback(
    async (latlng, georasterState) => {
      try {
        // Assuming geoblaze.identify is async and returns a Promise
        const identifyValue = await geoblaze.identify(georasterState, [
          latlng.lng,
          latlng.lat,
        ]);

        if (identifyValue) {
          const formattedValue = parseFloat(identifyValue).toFixed(2);
          const content = `<div>Value: ${formattedValue}</div>`;

          if (!popupRef.current) {
            popupRef.current = L.popup()
              .setLatLng([latlng.lat, latlng.lng])
              .setContent(content)
              .openOn(map);
          } else {
            popupRef.current
              .setLatLng([latlng.lat, latlng.lng])
              .setContent(content)
              .openOn(map);
          }
        }
      } catch (error) {
        console.error("Error identifying value:", error);
      }
    },
    [map]
  );

  useEffect(() => {
    // Store the current min-max values in the refs
    prevMinValRef.current = minval;
    prevMaxValRef.current = maxval;
  }, [minval, maxval]);

  useEffect(() => {
    // console.log(rampScale, rampType, colorBlind);
    if (rampScale !== "relative") {
      // console.log(rampScale);
      return; // Exit if colorScale is not 'relative'
    }

    // GeoRasterLayer is actually rendered before trying to update it
    if (geoTiffLayerRef.current) {
      // Regenerate color scale
      var newColorScale = SetColorScale(
        minval,
        maxval,
        parameter,
        rampType,
        rampScale,
        colorBlind
      );
      // Corrected condition based on the provided variables and logic

      if (rampType === "continuous") {
        // Update GeoRasterLayer options
        var newOptions = {
          ...geoTiffLayerRef.current.options,
          pixelValuesToColorFn: function (pixelValues) {
            var pixelValue = pixelValues[0]; // assuming one band
            if (!(isGFSOrERA5Model && isTemperatureParameter)) {
              if (pixelValue === 0 || isNaN(pixelValue)) return null;
            }
            var scaledPixelValue = (pixelValue - minval) / (maxval - minval);
            var color = newColorScale(scaledPixelValue).hex();
            return color;
          },
        };
      } else {
        let colorScaleInfo = SetColorScale(
          minval,
          maxval,
          parameter,
          rampType,
          rampScale,
          colorBlind
        );
        var colorScale = colorScaleInfo.colorScale;

        const getColor = (value) => {
          var val = value[0];
          if (val === 0 || isNaN(val)) return null;
          return colorScale(val).hex();
        };

        var newOptions = {
          ...geoTiffLayerRef.current.options,
          pixelValuesToColorFn: getColor,
          resolution: 128,
          opacity: opacity,
        };
      }
      // Remove the previous layer
      map.removeLayer(geoTiffLayerRef.current);

      // Create a new GeoRasterLayer with the new options
      geoTiffLayerRef.current = new GeoRasterLayer(newOptions);

      // Add the new layer to the map
      map.addLayer(geoTiffLayerRef.current);
    }
  }, [minval, maxval]);

  useEffect(() => {
    setManualEdit(true);
  }, [minval, maxval]);

  useEffect(() => {
    if (isLocked) {
      // Lock the current minval and maxval
      setLockedMinMax({
        ...lockedMinMax,
        [parameter]: { min: minval, max: maxval },
      });
      setManualEdit(false); // Reset manual edit flag
    } else if (!manualEdit && lockedMinMax[parameter]) {
      // Reset to locked values only if there hasn't been a manual edit
      setminval(lockedMinMax[parameter].min);
      setmaxval(lockedMinMax[parameter].max);
    }
  }, [isLocked]);

  useEffect(() => {
    function onMapClick(e) {
      const popupContent = document.createElement("div");

      const coords = document.createElement("p");
      const lat = Number(e.latlng.lat).toFixed(3);
      const lon = Number(e.latlng.lng).toFixed(3);
      ForecastCoords.Provider = [lon, lat];
      coords.textContent = `Lat: ${lat.toString()}  Lon: ${lon.toString()}`;
      popupContent.appendChild(coords);

      const forecastContainer = document.createElement("div");
      const root = createRoot(forecastContainer);

      root.render(<ShowForecast />);
      popupContent.appendChild(forecastContainer);

      L.popup().setLatLng(e.latlng).setContent(popupContent).openOn(map);
    }

    map.on("contextmenu", onMapClick);

    return () => {
      map.off("contextmenu", onMapClick);
    };
  }, []);

  useEffect(() => {
    if (geoTiffLayerRef.current) {
      map.removeLayer(geoTiffLayerRef.current);
    }

    if (url) {
      fetch(url)
        .then((response) => response.arrayBuffer())
        .then((arrayBuffer) => {
          parseGeoraster(arrayBuffer).then((georaster) => {
            let min, max;
            const newMin = parseFloat(georaster.mins[0]);
            const newMax = georaster.maxs[0];

            setGeorasterState(georaster);

            // console.log('isLocked', isLocked)
            // Modify this part inside your existing code where you set min and max
            if (isLocked) {
              if (lockedMinMax[parameter]) {
                min = lockedMinMax[parameter].min;
                max = lockedMinMax[parameter].max;
              } else {
                min = newMin;
                max = newMax;
              }
            } else if (rampScale === "relative") {
              min = newMin;
              max = newMax;
              setLockedMinMax({
                ...lockedMinMax,
                [parameter]: { min, max },
              });
            } else if (rampScale === "static") {
              const { min: staticMin, max: staticMax } =
                getStaticWeatherRange(parameter);
              min = staticMin;
              max = staticMax;
              // console.log('minmax static',min,max)
            } else {
              min = newMin;
              max = newMax;
            }

            const range = max - min;
            if (range === 0) {
              min = 0;
              max = 10;
            }

            // Combine state updates
            setminval(min);
            setmaxval(max);

            if (rampType === "continuous") {
              if (rampScale === "static") {
                const { min: staticMin, max: staticMax } =
                  getStaticWeatherRange(parameter);
                setminval(staticMin);
                setmaxval(staticMax);
              } else {
                // Handle other cases
                setminval(min);
                setmaxval(max);
              }

              const staticMin = getStaticWeatherRange(parameter).min;
              const staticMax = getStaticWeatherRange(parameter).max;

              if (rampScale === "static") {
                min = staticMin;
                max = staticMax;
              }
              // console.log('minmax static3',staticMin,staticMax)
              var colorScale = SetColorScale(
                min,
                max,
                parameter,
                rampType,
                rampScale,
                colorBlind
              );
              colorScaleRef.current = colorScale;
              setLockedMinMax({
                ...lockedMinMax,
                [parameter]: { min, max },
              });
              // console.log('colors', min, max, max - min)
              if (parameter !== "combined") {
                var options = {
                  pixelValuesToColorFn: function (pixelValues) {
                    var pixelValue = pixelValues[0]; // there's just one band in this raster

                    // if there's zero wind, don't return a color
                    if (!(isGFSOrERA5Model && isTemperatureParameter)) {
                      if (pixelValue === 0 || isNaN(pixelValue)) return null;
                    }

                    // if (
                    //   (weatherParam.Provider === "radar" || weatherParam.Provider === "cloudcover" || weatherParam.Provider === "precipitation") &&
                    //   (pixelValue === 0 || isNaN(pixelValue))
                    // ) {
                    //   return null; // don't return a color for 0 value in wind or precipitation
                    // }

                    // scale to 0 - 1 used by chroma
                    var scaledPixelValue = (pixelValue - min) / (max - min);

                    var color = colorScale(scaledPixelValue).hex();
                    return color;
                  },
                  resolution: 128,
                  opacity: opacity,
                };
              } else {
                var options = {
                  resolution: 128,
                  opacity: opacity,
                  georaster: georaster,
                  pixelValuesToColorFn: function (pixelValues) {
                    var pixelValue = pixelValues[0]; // assuming one band
                    if (pixelValue === 0 || isNaN(pixelValue)) return null;

                    // Define ranges for each precipitation type
                    var rainRange = [1, 2];
                    var freezingRainRange = [2, 3];
                    var sleetRange = [3, 4];
                    var snowRange = [4, 5];
                    var mixRange = [5, 6];

                    if (
                      pixelValue >= rainRange[0] &&
                      pixelValue < rainRange[1]
                    ) {
                      // Map to rain colors
                      return chroma
                        .scale(comRainScale)
                        .mode("lch")(pixelValue - rainRange[0])
                        .hex();
                    } else if (
                      pixelValue >= freezingRainRange[0] &&
                      pixelValue < freezingRainRange[1]
                    ) {
                      // Map to freezing rain colors
                      return chroma
                        .scale(comFreezingRainScale)
                        .mode("lch")(pixelValue - freezingRainRange[0])
                        .hex();
                    } else if (
                      pixelValue >= sleetRange[0] &&
                      pixelValue < sleetRange[1]
                    ) {
                      // Map to sleet colors
                      return chroma
                        .scale(comSleetScale)
                        .mode("lch")(pixelValue - sleetRange[0])
                        .hex();
                    } else if (
                      pixelValue >= snowRange[0] &&
                      pixelValue < snowRange[1]
                    ) {
                      // Map to snow colors
                      return chroma
                        .scale(comSnowScale)
                        .mode("lch")(pixelValue - snowRange[0])
                        .hex();
                    } else if (
                      pixelValue >= mixRange[0] &&
                      pixelValue < mixRange[1]
                    ) {
                      // Map to brown colors
                      return chroma
                        .scale(comMixScale)
                        .mode("lch")(pixelValue - mixRange[0])
                        .hex();
                    } else {
                      // Default or undefined values
                      return null;
                    }
                  },
                };
              }
              // Discrete
            } else {
              let colorScaleInfo = SetColorScale(
                min,
                max,
                parameter,
                rampType,
                rampScale,
                colorBlind
              );
              var colorScale = colorScaleInfo.colorScale;

              const getColor = (value) => {
                var val = value[0];
                if (val === 0 || isNaN(val)) return null;
                return colorScale(val).hex();
              };

              var options = {
                pixelValuesToColorFn: getColor,
                resolution: 128,
                opacity: opacity,
              };
            }

            options.georaster = georaster;

            geoTiffLayerRef.current = new GeoRasterLayer(options);

            map.addLayer(geoTiffLayerRef.current);

            if (isPopup) {
              updatePopupContent(latlngstate, georaster);
            }

            //otherwise previous map renders again
            MyTiff.Provider = "";
          });
        });
    }

    // Cleanup
    return () => {
      if (geoTiffLayerRef.current) {
        map.removeLayer(geoTiffLayerRef.current);
      }
    };
  }, [
    weather_file,
    rampType,
    rampScale,
    colorBlind,
    opacity,
    updatePopupContent,
  ]);

  useEffect(() => {
    const handleMapClickForPopup = (evt) => {
      // Your popup logic here
      setIsPopup(true);

      let popup;

      if (Measuring.Provider) return;

      var latlng = map.mouseEventToLatLng(evt.originalEvent);
      setLatLngState(latlng);

      // Assuming bbox is an array of [southWest, northEast] coordinates
      // where each coordinate is a [lat, lng] array.
      const bbox = geoTiffLayerRef.current.getBounds();
      const southWest = bbox.getSouthWest();
      const northEast = bbox.getNorthEast();

      if (
        latlng.lat >= southWest.lat &&
        latlng.lat <= northEast.lat &&
        latlng.lng >= southWest.lng &&
        latlng.lng <= northEast.lng
      ) {
        if (!popup) {
          popup = L.popup().setLatLng([latlng.lat, latlng.lng]).openOn(map);
        } else {
          popup.setLatLng([latlng.lat, latlng.lng]);
        }

        updatePopupContent(latlng, georasterState);

        // let identifyValue = parseFloat(
        //   geoblaze.identify(geoTiffLayerRef.current, [latlng.lng, latlng.lat])
        // ).toFixed(2);

        // updatePopupContent(latlng, georaster)
        // const popupContent = document.createElement("div");
        // popupContent.className = styles.darkTiffTooltip;
        // popupContent.textContent = `Value: ${identifyValue}`;

        // popup.setContent(popupContent).openOn(map);
        setIsPopup(true);
        ignoreNextCloseRef.current = true;
      }
    };
    const handlePopupClose = () => {
      if (ignoreNextCloseRef.current) {
        // Ignore this close event and reset the flag
        ignoreNextCloseRef.current = false;
        return;
      }
      setIsPopup(false); // Now we actually close the popup, so update state accordingly
    };

    map.on("click", handleMapClickForPopup);
    map.on("popupclose", handlePopupClose);

    // Cleanup function to remove the event listeners
    return () => {
      map.off("click", handleMapClickForPopup);
      map.off("popupclose", handlePopupClose);
    };
  }, [georasterState, map, updatePopupContent]);

  if (weather_file && activeTabKey !== "correlation" && activeTabKey !== "graph") {
    if (rampType === "continuous") {
      return (
        <div className={styles.legend}>
          <LegendContinuous
            min={minval}
            max={maxval}
            setminval={setminval}
            setmaxval={setmaxval}
            rampScale={rampScale}
            isLocked={isLocked}
            setIsLocked={setIsLocked}
            colorBlind={colorBlind}
            parameter={parameter}
          />
        </div>
      );
    } else {
      return (
        <div className={styles.legend}>
          <LegendDiscrete
            min={minval}
            max={maxval}
            setminval={setminval}
            setmaxval={setmaxval}
            rampScale={rampScale}
            isLocked={isLocked}
            setIsLocked={setIsLocked}
            colorBlind={colorBlind}
            parameter={parameter}
          />
        </div>
      );
    }
  } else {
    return null;
  }
}

export { Measuring };
