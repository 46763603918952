// import { GeoJSON } from "react-leaflet";
import Papa from "papaparse";
import proj4 from "proj4";
import { Icon } from "leaflet";
import {
  MapContainer,
  TileLayer,
  WMSTileLayer,
  LayersControl,
  // Marker,
  // FeatureGroup,
} from "react-leaflet";
import { Link as RouterLink } from "react-router-dom";
import "leaflet-draw";
import LeafletDrawShapes from "./LeafletDrawShapes";
import React, { useState, useEffect, createContext, } from "react";
import { MyTiff } from "./SideMenu/SideMenu";
import { CoordinatesProvider } from "./CoordinatesContext";
import { CoordinatesProviderLoc } from "./CoordinatesContextLoc";

import SideMenu from "./SideMenu/SideMenu";
import LLCForm from "./LLCForm";
import Title from "./Title";
import GeoTiffLayer from "./LoadRaster";
import MeasureDistanceTool from "./Measure/MeasureDistanceTool";
import MeasureAreaTool from "./Measure/MeasureAreaTool";
import Measuring from "./Measure/MeasuringContext";
import SocialMedia from "./SocialMedia/SocialMedia";
// import Banner from "./NewVideoBanner";

import "./LeafletOverride.css";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import styles from "./Map.module.css";

window.proj4 = proj4;

const location = [45, -90];
const zoom = 3;
const maxZoom = 12;
const minZoom = 2;
const southWest = [-90.0, -260];
const northEast = [90.0, 290.0];
const bounds = [southWest, northEast];
const ForecastCoords = createContext();
ForecastCoords.Provider = null;
const csvToGeoJSON = (data) => {
  // console.log(data); // Log the parsed data to check its structure

  return {
    type: "FeatureCollection",
    features: data
      .map((row) => {
        // console.log(row); // Log each row to check its values

        const latitude = parseFloat(row.latitude);
        const longitude = parseFloat(row.longitude);

        if (isNaN(latitude) || isNaN(longitude)) {
          console.log(
            "Invalid latitude or longitude:",
            row.latitude,
            row.longitude
          );
          return null; // Skip invalid coordinates
        }

        return {
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: [longitude, latitude],
          },
          properties: {
            brightness: row.brightness,
            scan: row.scan,
            track: row.track,
            // Include other properties as needed
          },
        };
      })
      .filter((feature) => feature !== null), // Filter out null features
  };
};

export default function WeatherMap() {
  // Fetching and parsing CSV data

  const [geojsonData, setGeojsonData] = useState(null);
  const [weatherFile, setWeatherFile] = useState(null);
  const [isActive, setIsActive] = useState(true);
  const [isMeasuringArea, setIsMeasuringArea] = useState(false);
  const [isMeasuringDistance, setIsMeasuringDistance] = useState(false);
  const [colorBlind, setColorBlind] = useState(false);
  const [opacity, setOpacity] = useState(0.75);
  const [submitted, setSubmitted] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("instant");
  const [correlate, setCorrelate] = useState(false);
  const [firstPolygonCoords, setFirstPolygonCoords] = useState(null);
  const [secondPolygonCoords, setSecondPolygonCoords] = useState(null);

  const [rampType, setRampType] = useState("continuous");
  const [rampScale, setRampScale] = useState("static");

  const [showPopup, setShowPopup] = useState(false);
  const [popupImageSrc, setPopupImageSrc] = useState("");

  // Independent state for model selection in each tab
  const [rangeModel, setRangeModel] = useState("gfs");
  const [instantModel, setInstantModel] = useState("gfs");
  const [graphModel, setGraphModel] = useState("gfs");
  const [correlationModel, setCorrelationModel] = useState("era5_monthly");

  const [instantParameter, setInstantParameter] = useState("combined");
  const [rangeParameter, setRangeParameter] = useState("temperature");
  const [graphParameter, setGraphParameter] = useState("temperature");
  const [correlationParameter, setCorrelationParameter] = useState("temperature");
  const [correlationParameter2, setCorrelationParameter2] = useState("precipitation");

  // Function to determine which model to use based on the active tab
  const getActiveModel = () => {
    switch (activeTabKey) {
      case "instant":
        return instantModel;
      case "range":
        return rangeModel;
      case "graph":
        return graphModel;
      case "correlation":
          return correlationModel;
      default:
        return instantModel;
    }
  };

    // Function to determine which model to use based on the active tab
    const getActiveParameter = () => {
      switch (activeTabKey) {
        case "instant":
          return instantParameter;
        case "range":
          return rangeParameter;
        case "graph":
          return graphParameter;
        case "correlation":
          return correlationParameter;
        default:
          return instantParameter;
      }
    };

  function displayPopupImage(imageHref) {
    setPopupImageSrc(imageHref);
    setShowPopup(true);
  }

  function closePopup() {
    URL.revokeObjectURL(popupImageSrc); // Cleanup the object URL
    setShowPopup(false);
  }

  // this is important
  MyTiff.Provider = useState(null);

  const handleDistanceToolToggle = () => {
    setIsMeasuringDistance((prev) => !prev);
    if (isMeasuringArea) {
      setIsMeasuringArea(false);
    }
    Measuring.Provider = !isMeasuringDistance;
  };

  const handleAreaToolToggle = () => {
    setIsMeasuringArea((prev) => !prev);
    if (isMeasuringDistance) {
      setIsMeasuringDistance(false);
    }
    Measuring.Provider = !isMeasuringArea;
  };

  // in the future we will download to s3 using a lambda function. lambda will also grab only needed fire data and change to geojson file. then the below url can point to s3 geojson file.n
  // const csvUrl = 'https://firms.modaps.eosdis.nasa.gov/data/active_fire/modis-c6.1/csv/MODIS_C6_1_Canada_24h.csv';
  // useEffect(() => {
  //   axios.get(csvUrl, { responseType: 'blob' })
  //     .then(response => {
  //       response.data.text().then(text => {
  //         const parsedData = Papa.parse(text, { header: true, dynamicTyping: true });
  //         const geojson = csvToGeoJSON(parsedData.data);
  //         setGeojsonData(geojson);
  //       });
  //     });
  // }, []);

  const fireIconMarker = new Icon({
    iconUrl: "./assets/flame-icon.png",
    iconSize: [18, 18],
    iconAnchor: [16, 16],
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch('/assets/MODIS_C6_1_Global_24h.csv');
  //       const text = await response.text();
  //       const { data } = Papa.parse(text, { header: true, dynamicTyping: true });
  //       const geojson = csvToGeoJSON(data);
  //       setGeojsonData(geojson);
  //     } catch (error) {
  //       console.error('Error fetching CSV file:', error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  // useEffect(() => {
  //   Measuring.Provider = isMeasuringArea || isMeasuringDistance;
  //   console.log(
  //     "WeatherMap - Updating Measuring.Provider:",
  //     Measuring.Provider
  //   );
  // }, [isMeasuringArea, isMeasuringDistance]);

  useEffect(() => {
    // Update correlate based on the active tab
    if (activeTabKey === "correlation") {
      setCorrelate(true);
    } else {
      setCorrelate(false);
    }
  }, [activeTabKey]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("/assets/MODIS_C6_1_Global_24hb.csv");
        const text = await response.text();
        const { data } = Papa.parse(text, {
          header: true,
          dynamicTyping: true,
        });

        // Filter out objects with an frp value of less than 1000
        // const filteredData = data.filter(item => item.frp > 100);
        const filteredData = data.filter((item) => item.confidence === 100);

        const geojson = csvToGeoJSON(filteredData);
        setGeojsonData(geojson);
      } catch (error) {
        console.error("Error fetching CSV file:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div>
      <CoordinatesProviderLoc>
        <CoordinatesProvider>
          {showPopup && (
            <div className={styles.popupContainer}>
              <div className={styles.popupContent}>
                <img
                  src={popupImageSrc}
                  alt="Graph"
                  className={styles.popupImage}
                />
                <button
                  onClick={closePopup}
                  className={styles.closePopupButton}
                >
                  X
                </button>
              </div>
            </div>
          )}
        <SideMenu
          setWeatherFile={setWeatherFile}
          setRampType={setRampType}
          setRampScale={setRampScale}
          rampType={rampType}
          rampScale={rampScale}
          setColorBlind={setColorBlind}
          parameter={getActiveParameter()}
          parameter2 = {correlationParameter2}
          setParameter={(model) => {
            // Update the model for the currently active tab
            switch (activeTabKey) {
              case "instant":
                setInstantParameter(model);
                break;
              case "range":
                setRangeParameter(model);
                break;
              case "graph":
                setGraphParameter(model);
                break;
              case "correlation":
                setCorrelationParameter(model);
              break;
              default:
                setInstantParameter(model);
            }
          }}
          setParameter2={setCorrelationParameter2}
          opacity={opacity}
          setOpacity={setOpacity}
          selectedModel={getActiveModel()}
          setSelectedModel={(model) => {
            // Update the model for the currently active tab
            switch (activeTabKey) {
              case "instant":
                setInstantModel(model);
                break;
              case "range":
                setRangeModel(model);
                break;
              case "graph":
                setGraphModel(model);
                break;
              case "correlation":
                setCorrelationModel(model);
                break;
              default:
                setInstantModel(model);
            }
          }}
          setSubmitted={setSubmitted}
          activeTabKey={activeTabKey}
          setActiveTabKey={setActiveTabKey}
          displayPopupImage={displayPopupImage}
          setCorrelate={setCorrelate}
          firstPolygonCoords={firstPolygonCoords}
          secondPolygonCoords={secondPolygonCoords}
        />

          <MapContainer
            className={styles.mapContainer}
            // style={{ height: height, width: width, zIndex: "0" }}
            zoom={zoom}
            center={location}
            maxBounds={bounds}
            maxZoom={maxZoom}
            minZoom={minZoom}
            weatherFile={weatherFile}
            // maxBoundsViscosity='.5'
          >
            <RouterLink
              className={styles.aboutlink}
              to="/about"
              style={{ marginLeft: "10px" }}
            >
              About
            </RouterLink>
            {/* <Banner /> */}
            <SocialMedia />

            <LLCForm />
            <Title />
            <MeasureAreaTool
              setIsActive={setIsActive}
              isActive={isMeasuringArea}
              onToggle={handleAreaToolToggle}
            />
            <MeasureDistanceTool
              setIsActive={setIsActive}
              isActive={isMeasuringDistance}
              onToggle={handleDistanceToolToggle}
            />
            <LayersControl position="bottomleft">
              <LayersControl.BaseLayer name="OpenStreetMap" checked={true}>
                <TileLayer
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                />
              </LayersControl.BaseLayer>

              <LayersControl.Overlay name="Radar RRAI">
                <WMSTileLayer
                  url="https://geo.weather.gc.ca/geomet?"
                  layers="RADAR_1KM_RRAI"
                  format="image/png"
                  transparent={true}
                  opacity={0.9}
                  zIndex={5}
                  attribution="Weather data © Environment and Climate Change Canada"
                />
              </LayersControl.Overlay>

              {/* <LayersControl.Overlay name="Radar RSNO">
            <WMSTileLayer
              url="https://geo.weather.gc.ca/geomet?"
              layers="RADAR_1KM_RSNO"
              format="image/png"
              transparent={true}
              opacity={0.9}
              zIndex={5}
              attribution="Weather data © Environment and Climate Change Canada"
            />
          </LayersControl.Overlay> */}
              {/* <LayersControl.Overlay name="Radar NOAA">
          <WMSTileLayer
            url="https://idpgis.ncep.noaa.gov/arcgis/services/NWS_Observations/radar_base_reflectivity/MapServer/WMSServer?"
            layers="1,2,3"
            format="image/png"
            transparent={true}
            opacity={0.9}
            zIndex={5}
            attribution="Weather data © National Weather Service"
          />
          </LayersControl.Overlay> */}
              <LayersControl.Overlay name="Total Cloud Cover">
                <WMSTileLayer
                  url="https://geo.weather.gc.ca/geomet?"
                  layers="GDPS.ETA_NT"
                  format="image/png"
                  transparent={true}
                  opacity={0.4}
                  zIndex={5}
                  attribution="Weather data © Environment and Climate Change Canada"
                />
              </LayersControl.Overlay>

              {/* <LayersControl.Overlay name="Wild Fires">
              <FeatureGroup>
                {geojsonData && (
                  <>
                    {geojsonData.features.map((feature, index) => (
                      <Marker
                        key={index}
                        position={[
                          feature.geometry.coordinates[1],
                          feature.geometry.coordinates[0],
                        ]}
                        icon={fireIconMarker}
                      />
                    ))}
                  </>
                )}
              </FeatureGroup>
            </LayersControl.Overlay> */}
            </LayersControl>

            <GeoTiffLayer
              weather_file={weatherFile}
              rampType={rampType}
              rampScale={rampScale}
              colorBlind={colorBlind}
              activeTabKey={activeTabKey}
              parameter={getActiveParameter()}
              setParameter={(model) => {
                // Update the model for the currently active tab
                switch (activeTabKey) {
                  case "instant":
                    setInstantParameter(model);
                    break;
                  case "range":
                    setRangeParameter(model);
                    break;
                  case "graph":
                    setGraphParameter(model);
                    break;
                  case "correlation":
                    setCorrelationParameter(model);
                    break;
                  default:
                    setInstantParameter(model);
                }
              }}
              opacity={opacity}
              selectedModel={getActiveModel()}
            />
            <LeafletDrawShapes
              setWeatherFile={setWeatherFile}
              isActive={isActive}
              isMeasuringArea={isMeasuringArea}
              isMeasuringDistance={isMeasuringDistance}
              submitted={submitted}
              setSubmitted={setSubmitted}
              activeTabKey={activeTabKey}
              correlate={correlate}
              firstPolygonCoords={firstPolygonCoords}
              setFirstPolygonCoords={setFirstPolygonCoords}
              secondPolygonCoords={secondPolygonCoords}
              setSecondPolygonCoords={setSecondPolygonCoords}
            />
          </MapContainer>
        </CoordinatesProvider>
        </CoordinatesProviderLoc>
      </div>
      {/* <div 
      id="ad-container"
      className={styles.ad_banner}
      >
      Ad Banner Content Here
      </div> */}
    </div>
  );
}
export { ForecastCoords };